<template >
  <v-row class="d-flex flex-column">
    <v-app-bar class="not-print d-flex justify-end" color="#eeeeee" app flat>
      <v-btn @click="$doc.print()" class="mr-5" color="primary">
        <v-icon left>mdi-printer</v-icon>
        Imprimir
      </v-btn>

      <!-- <v-btn @click="download()"  color="primary">
        <v-icon left>mdi-download</v-icon>
        Baixar
      </v-btn> -->
    </v-app-bar>
    <div id="app" class="page" ref="document">
      <div class="text-center text-h4">
        {{ company.name }}
      </div>

      <div class="pl-3">
        Prontuario do {{ customerAlias() }}: <b>{{ customer.name }}</b>
      </div>

      <v-col>
        <template v-for="(question, index) in professional_record">
          <div :key="index">
            <template v-if="question.type == 'question'">
              <div v-if="question.answer">
                <span class="d-flex align-center justify-space-between mb-2">
                  {{ question.question }}
                </span>

                <span v-text="question.answer" />
                <hr class="my-4" />
              </div>
            </template>
            <template v-if="question.type == 'heading'">
              <div
                class="d-flex justify-space-between pr-4"
                style="width: 100%"
              >
                <h3 class="mt-6 mb-2">
                  {{ question.heading }}
                </h3>
              </div>
            </template>
          </div>
        </template>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  data() {
    return {
      professional_record: [],
      customerAlias,
      customer: {},
    };
  },

  created() {
    this.$loading.start();
    this.$http
      .show("customer/customer", this.$route.params.id)
      .then((response) => {
        this.customer = response.customer;

        this.professional_record = JSON.parse(
          this.customer.customer_professional_record.professional_record
        );

        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },
  },

  methods: {
    download() {
      this.$doc.download(
        this.$refs.document,
        ` Prontuario do ${this.customerAlias()}-${this.customer.name}`
      );
    },
  },
};
</script>

<style lang="sass" scoped>
#app

.not-print
  display: flex
  justify-content: flex-center

.page
  background-color: #fff

@page
  size: A4
  margin: 0.5cm
  padding: 0.5cm

@media print
  .not-print
    display: none
    visibility: hidden
  .v-main
    padding: 0px 0px 0px 0px !important
    height: 100%
</style>
